import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {useParams} from "react-router-dom";
import AssetSubmitForm from '../AssetSubmitForm/assetSubmitForm';
import Grid from '../Grid/grid';
import style from "./previewLayout.module.css";
import {AssetInfo} from "../../Helper/helper";


function PreviewLayout() {
  const {assetid,userName} = useParams();
  const [selectedAssetIds,setSelectedAssetIds] = useState();
  const [previewPortalView,setPreviewPortalView] = useState(true);
    
  return (    
    <AssetInfo.Provider value={{assetId:[]}}>
      <div className={style.previewPortalContainer}>
      {(previewPortalView)?(
          <Row className={style.assetResultContainer}>        
            <Col xs={3} className={`${style.leftContainer} p-0`}>
              <AssetSubmitForm setUserName={userName?.split("=")[1]} setPreview ={setPreviewPortalView} getAssetIds={selectedAssetIds}></AssetSubmitForm>
            </Col>
            <Col xs={9} className={`${style.rightContainer} p-0`}>
              <Grid viewLayout="preview" setAssetIds={assetid} setSelectedAssetId={setSelectedAssetIds}></Grid>
            </Col>
          </Row>   
        ):(
          <Row className={style.assetSuccessContainer}>        
            <Col xs={12} className={`${style.successContainer} p-0`}>
                <div>
                    <p className={style.resultStatus}>The selected assets were sent successfully to the mentioned email address(es)</p>
                </div>
            </Col> 
          </Row>   
        )}        
      </div> 
    </AssetInfo.Provider>
  );
  
}

export default PreviewLayout;
