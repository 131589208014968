import React, { useState, useEffect, useContext } from "react";
import { Accordion } from "react-bootstrap";
import style from './filter.module.css';
import FilterData from './filterData.json';
import axios from "axios";
import { EskoAPI } from "../../Helper/helper";
import ErrorPopup from "../ErrorPopup/errorPopup";

const Filter = (props) => {   
    
    const API = useContext(EskoAPI);
    const transAssetId = props.transactionAssets;
    const [filterDataStructure,setFilterDataStructure] = useState({});
    const [freeTextVal, setFreeTextVal] = useState({});
    //const [filterDataValue, setFilterDataValue] = useState(false);
    //const [errTextShow, setErrorTextShow] = useState({});
    const [filterTextShow, setfilterTextShow] = useState({});
    const [clearSearchBtnStatus,setClearSearchBtnStatus] = useState(false);
    const [searchBtnStatus,setSearchBtnStatus] = useState(false);
    const [errorPopupShow, setErrorPopupShow] = useState(false);
    const [errorTitle, setErrorTitle] = useState([]);
    const [errorContent, setErrorContent] = useState([]);
    const [errorValue, setErrorValue] = useState([]);

    const filterSearch = (category, subcategory) => {
        setClearSearchBtnStatus(true);
        setSearchBtnStatus(true);
        setFreeTextVal({...freeTextVal, [category]: subcategory});       
        //setErrorTextShow({...errTextShow,[category]:false});
        setfilterTextShow({...filterTextShow,[category]: true});
    }

    const inputFilterOnchange = (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        setClearSearchBtnStatus(true);
        setSearchBtnStatus(true);
        setFreeTextVal({...freeTextVal,[name]: value});
    }

    // const freeTextSearch = (category) => {
    //     if (freeTextVal[category] !== undefined) {
    //         //setErrorTextShow({...errTextShow,[category]:false});
    //         setfilterTextShow({...filterTextShow,[category] : true});            
    //     } else {
    //         setfilterTextShow({...filterTextShow,[category] : false});
    //        // setErrorTextShow({...errTextShow,[category]:true});            
    //     }
    // }

    const clearSearch = () => {
        setClearSearchBtnStatus(false);
        setSearchBtnStatus(false);
        setfilterTextShow({});
        setFreeTextVal(filterDataStructure);
        if(props.currentAssetId != transAssetId){
            props.setFilterRefresh(true);
            props.setGridValue(transAssetId);
        }         
    }

    const clearSearchFilter = (category) => {        
        setFreeTextVal({...freeTextVal,[category]:""});
        setfilterTextShow({...filterTextShow,[category] : false}); 
        let filterDataValueCheck = Object.values({...freeTextVal,[category]:""});        
        let filterDataCheck = filterDataValueCheck?.filter((i) => { 
            return i != "";            
        });

        if(filterDataCheck.length == 1){   
            clearSearch();   
        }
        //setErrorTextShow({...errTextShow,[category]:false});
    }

    const inputKeyupFilter = (event) => {
        if(event.keyCode == 13 && event.target.value != ""){
            setClearSearchBtnStatus(true);
            setSearchBtnStatus(true);
            setfilterTextShow({...filterTextShow,[event.target.name] : true});  
        }
    }

    const inputBlurFilter = (event) => {
        if(event.target.value != ""){
            setClearSearchBtnStatus(true);
            setSearchBtnStatus(true);
            setfilterTextShow({...filterTextShow,[event.target.name] : true});  
        }
    }

    useEffect(()=>{    
        let filterDataStructure = {};    
        FilterData.map((i) => { 
            filterDataStructure[`${i.resultType}`] = "";
        });    
        filterDataStructure.uuid =  props?.transactionId?.split("=")[1];;  
        setFreeTextVal(filterDataStructure);
        setFilterDataStructure(filterDataStructure);
    },[]);

    useEffect(()=>{
        let filterDataValueCheck = Object.values(freeTextVal);        
        let filterDataCheck = filterDataValueCheck?.filter((i) => { 
            return i != "";            
        });

        if(filterDataCheck.length == 1){             
            setClearSearchBtnStatus(false);
            setSearchBtnStatus(false);   
           // props.setFilterRefresh(true);
           // props.setGridValue(transAssetId);        
        }
    },[freeTextVal]);

    const filterResult = () => {
        let filterDataValueCheck = Object.values(freeTextVal);        
        let filterDataCheck = filterDataValueCheck?.filter((i) => { 
            return i != "";            
        });
        
        //props.setFilterRefresh(true);
        if(filterDataCheck.length == 1){             
            setClearSearchBtnStatus(false);
            setSearchBtnStatus(false);
            props.setGridValue(transAssetId);        
        }else{  
            const headers = {
                'Content-Type': 'application/json'
            }
            axios
            .post(API.apiURL+"SearchbyMetaData?subscription-key="+API.subKey,freeTextVal,{headers: headers})
            .then((response) => {
                if(response.data.eskoResponse.status.toLowerCase() === 'succeeded'){
                    if(response.data.assetId === ""){
                        setErrorTitle("Errors");
                        setErrorContent("The filtering criteria does not have any assets");
                        setErrorValue("");
                        setErrorPopupShow(true);
                    }else if(response.data.assetId !== transAssetId){
                        props.setGridValue(response.data.assetId);
                        props.setFilterRefresh(true);                        
                    }else if(response.data.assetId == transAssetId){
                        setErrorTitle("Information");
                        setErrorContent("The filtering criteria returns the same assets");
                        setErrorValue("");
                        setErrorPopupShow(true);
                    }
                }else if(response.data.eskoResponse.status.toLowerCase() === 'failure'){
                    if(response.data.assetId === ""){
                        setErrorTitle("Errors");
                        setErrorContent("The filtering criteria does not have any assets");
                        setErrorValue("");
                        setErrorPopupShow(true);
                    }
                }           
            });
        }
    }
    
    return (
        
        <div className={`${style.filterContainer} filterContainer`}>
            <div className={style.filterSection}>
                <button className={(clearSearchBtnStatus)?(style.clearSearch):(`${style.clearSearch} ${style.disable}`)} onClick={() => clearSearch()}>Clear Search</button>
                <button className={(searchBtnStatus)?(style.searchFilter):(`${style.searchFilter} ${style.disable}`)} onClick={() => filterResult()}>Search</button>
            </div>
            <Accordion alwaysOpen>
                {
                    FilterData.map((i, index) => {                        
                        return (
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>{i.category}</Accordion.Header>
                                <Accordion.Body>
                                    <div className={style.filterValues}>
                                        {                                            
                                            (filterTextShow[i?.resultType])
                                            ?(
                                                <div className={style.filterText}>
                                                    <p className={style.filterTextVal}>{freeTextVal[`${i.resultType}`]}
                                                        <span className="material-icons" onClick={() => clearSearchFilter(i.resultType)}>close</span>
                                                    </p>
                                                </div>
                                            )
                                            :(
                                                (i.filterType === "static") ? (
                                                    i.subcategory.map((j) => {
                                                        return (
                                                            <p onClick={() => { filterSearch(i.resultType, j) }}>{j}</p>
                                                        )
                                                    })
                                                ) : (
                                                    <div className={style.freeText}>
                                                        <input type="text" name={i.resultType} placeholder={"Enter the " + i.category} value={freeTextVal[`${i.resultType}`]} onChange={inputFilterOnchange} onKeyUp={inputKeyupFilter} onBlur={inputBlurFilter}></input>
                                                        {/* <span className="material-icons" onClick={() => freeTextSearch(i.resultType)}>search</span>
                                                        {(errTextShow[i?.resultType]) && (<p>{"Please enter the " + i.category + " value"}</p>)} */}
                                                    </div>
                                                )
                                            )                                            
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        )
                    })
                }
            </Accordion>
            <ErrorPopup isShow={errorPopupShow} displayErrorPopup={setErrorPopupShow} errorTitle={errorTitle} errorContent={errorContent} errorValue={errorValue}></ErrorPopup>
        </div>
    );
}

export default Filter;