import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PortalEmailValidation from '../portalEmailValidation/portalEmailValidation';
import Filter from '../Filter/filter';
import { useParams } from "react-router-dom";
import Grid from '../Grid/grid';
import style from "./portalLayout.module.css";
import { AssetInfo } from "../../Helper/helper";



function PortalLayout() {
  const { id } = useParams();
  const [portalGridContainer, setPortalGridContainer] = useState(true);
  const [portalGridValue, setPortalGridValue] = useState();
  const [transactionAssets,setTransactionAssets] = useState();
  const [filterAssetRefresh,setFilterAssetRefresh] = useState(false);
  const [isGridchanged,setIsGridChanged] = useState(true);

  useEffect(()=>{
      if(filterAssetRefresh){
        setIsGridChanged(false);
        setTimeout(() => {      
          console.log("check")
          setIsGridChanged(true);
        }, 10);
      }      
      setFilterAssetRefresh(false);
  },[filterAssetRefresh]);

  return (
    <AssetInfo.Provider value={{ assetId: [] }}>
      <div className={style.portalContainer}>      
        {(portalGridContainer) ? (
          <Row className={style.assetResultContainer}>    
            <Col xs={12} className={`${style.rightContainer} p-0 portalGridContainer`}>
              <PortalEmailValidation setGridView={setPortalGridContainer} setTransAssets={setTransactionAssets} setGridValue={setPortalGridValue} setTranctionId={id}></PortalEmailValidation>
            </Col> 
          </Row> 
        ) : (       
          <Row className={style.assetResultContainer}>           
            <Col xs={3} className={`${style.leftContainer} p-0`}>
              <Filter setFilterRefresh={setFilterAssetRefresh} transactionAssets={transactionAssets} setGridValue={setPortalGridValue} transactionId={id} currentAssetId={portalGridValue}></Filter>
            </Col>
            <Col xs={9} className={`${style.rightContainer} p-0`}>
              {isGridchanged && <Grid viewLayout="portal" portalGridAssetValue={portalGridValue}></Grid>}
            </Col>    
          </Row>        
        )}
      </div>
    </AssetInfo.Provider>
  );
}

export default PortalLayout;
