import { React } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/header';
import PortalLayout from './Components/portalLayout/portalLayout';
import PreviewLayout from './Components/previewLayout/previewLayout';
import {EskoAPI} from './Helper/helper';

function App() {
  let APIURL = "";
  let subscriptionKey = "";
  console.log(process.env.NODE_ENV);
  if(window.location.origin.indexOf("mkt-eskoportal-dev") > -1){
    /* start Environment Variable Assigning */
    //subscriptionKey = process.env.REACT_APP_DEV_SUB_KEY;
    //APIURL = process.env.REACT_APP_DEV_SUB_KEY;
    /* end Environment Variable Assigning */

    /* start static Variable Assigning */
      APIURL = "https://api-dev.oceanspray.io/mkt/esko-webapi/Asset/";
      subscriptionKey = "8e2d924cb80642e3bc10d4c36639e9ee";
    /* end static Variable Assigning */
  }else if(window.location.origin.indexOf("portal-prod") > -1){
    /* start Environment Variable Assigning */
    //subscriptionKey = process.env.REACT_APP_PROD_SUB_KEY;
    //APIURL = process.env.REACT_APP_PROD_API_URL;
    /* end Environment Variable Assigning */

    /* start static Variable Assigning */
     APIURL = "https://api.oceanspray.io/mkt/esko-webapi/Asset/";
      subscriptionKey = "197fd800150c43ed983abd8dd807e2af";
    /* end static Variable Assigning */
  }else{
    /* start Environment Variable Assigning */
    //subscriptionKey = process.env.REACT_APP_LOCAL_SUB_KEY;
    //APIURL = process.env.REACT_APP_LOCAL_API_URL;
    /* end Environment Variable Assigning */
    
    /* start static Variable Assigning */
     APIURL = "http://40.70.158.46/Asset/";
     subscriptionKey = "12ffbc3b48df457d8745c5eee4c0ba93";
     /* end static Variable Assigning */
  }

  return (
    <EskoAPI.Provider value={{apiURL:APIURL,subKey:subscriptionKey}}>
      <div className="App">
        <Container fluid={true} className="p-0">
          <Row className="headerRowContainer">
            <Col className="headerColContainer">
              <Header></Header>
            </Col>
          </Row>
          <Routes>
            <Route path="/" exact element={<PreviewLayout />}></Route>
            <Route exact path="/preview/:assetid" element={<PreviewLayout />}></Route>
            <Route exact path="/preview/:assetid/:userName" element={<PreviewLayout />}></Route>            
            <Route path="/portal/:id" element={<PortalLayout />}></Route>
          </Routes>
        </Container>      
      </div>
    </EskoAPI.Provider>
  );
}

export default App;
