import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from 'react-paginate';
import style from './grid.module.css';
import AssetGridDetailPopup from "../AssetGridDetailPopup/assetGridDetailPopup";
import AssetDownloadPopup from "../DownloadPopup/assetDownloadPopup";
import ErrorPopup from "../ErrorPopup/errorPopup";
import axios from "axios";
import { EskoAPI, AssetInfo } from "../../Helper/helper";

const Grid = (props) => {

    const itemsPerPage = 20;    
    const API = useContext(EskoAPI);
    const selectedAssetIds = (props?.viewLayout === "portal") ? ((props?.portalGridAssetValue.length > 0)?(props?.portalGridAssetValue?.split(",")):(props?.portalGridAssetValue)) : (props?.setAssetIds?.split("=")[1].split(","));
    const assetInfo = useContext(AssetInfo);
    const [respData, setRespData] = useState(new Array((props?.viewLayout === "portal") ? ((props?.portalGridAssetValue.length > 0)?(props?.portalGridAssetValue?.split(",").length):(props?.portalGridAssetValue.length)) : (selectedAssetIds?.length)).fill(null));
    const [assetErrorId, setAssetErrorId] = useState([]);
    const [finalAssetID, setFinalAssetID] = useState(selectedAssetIds);
    const [detailPageData, setdetailPageData] = useState([]);
    const [popupShowStatus, setPopupShow] = useState(false);
    const [selectBtnStatus, setSelectBtnStatus] = useState(true);
    const [SelectBtnView, setSelectBtnView] = useState(true);
    const [clickSelectBtn, setClickSelectBtn] = useState("");
    const [APIloader, setAPIloader] = useState(false);
    const [assetCount, setAssetCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPageAssets, setCurrentPageAssets] = useState([]);
    const [newOffset, setNewOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(itemOffset + itemsPerPage);
    const [pageCount, setPageCount] = useState(1);
    const [downloadEnable, setDownloadEnable] = useState(false);
    const [downloadAllLabel, setDownloadAllLabel] = useState(true);
    const [downloadPopupShow, setDownloadPopupShow] = useState(false);
    const [downloadAssetID, setDownloadAssetID] = useState((props.viewLayout === "portal") ? (props.portalGridAssetValue) : (props?.setAssetIds?.split("=")[1].split(",")));
    const [errorPopupShow, setErrorPopupShow] = useState(false);
    const [errorTitle, setErrorTitle] = useState([]);
    const [errorContent, setErrorContent] = useState([]);
    const [errorValue, setErrorValue] = useState([]);

    const showPopupWithDetails = (i) => {
        setdetailPageData(i);
        setPopupShow(true);
    }

    const selectAsset = (i, index, selectStatus) => {
        let data = [...respData];
        let filteredAssetIds = [];
        if (selectStatus === "unSelectAll") {
            setClickSelectBtn("unSelectAll");
            data.map((i, index) => {
                if(i !== null){
                    i.isAssetselected = false;
                    i.downloadIconShow = true;
                }                
            })
            setDownloadEnable(true);
            setSelectBtnView(false);
            setDownloadAllLabel(false);
        } else if (selectStatus === "selectAll") {
            setClickSelectBtn("selectAll");
            data.map((i, index) => {
                if(i != null){
                    i.isAssetselected = true;
                    i.downloadIconShow = false;
                }                
            })
            setDownloadEnable(false);
            setSelectBtnView(true);
            setDownloadAllLabel(true);
        } else { 
            setClickSelectBtn(clickSelectBtn);           
            data[index].isAssetselected = (i.isAssetselected) ? false : true;
            if(data[index].isAssetselected){
                setDownloadEnable(false);
            }else{
                setSelectBtnView(false);
            }
            data[index].downloadIconShow = (data[index].isAssetselected) ? false : true;
        }
        setRespData([...data]);
        data.filter(data => {
            if(data != null && data.isAssetselected === true) {
                return filteredAssetIds.push(data.id);
            }
        });
        if (respData.length !== filteredAssetIds.length) {
            if(selectStatus === "selectAll"){
                setDownloadAllLabel(true); 
            }else{
                setDownloadAllLabel(false); 
            }          
            if(filteredAssetIds.length === 0){
                setDownloadEnable(true);
            }
        } else {
            setDownloadAllLabel(true);
            setSelectBtnView(true);
        }
        if(props.viewLayout === "preview"){
            assetInfo.assetId = filteredAssetIds;
        }else{
            if(selectStatus === "selectAll"){
                assetInfo.assetId = finalAssetID;
            }else{
                assetInfo.assetId = filteredAssetIds;
            }            
        }
        
    }

    const readableBytes = (bytes) => {
        var i = Math.floor(Math.log(bytes) / Math.log(1024)),
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
    }

    const handlePageClick = (page) => {
        const pageNewOffset = (page.selected * itemsPerPage) % respData.length;
        setNewOffset(pageNewOffset);
        setItemOffset(pageNewOffset);
        setEndOffset(pageNewOffset + itemsPerPage);
        if (respData[pageNewOffset] !== null) {
                    setCurrentPageAssets(respData.slice(pageNewOffset, pageNewOffset + itemsPerPage));
        } else {
            setAPIloader(true);
            getAssetDetails(pageNewOffset,pageNewOffset + itemsPerPage);
        }
    }

    const showDownloadPopup = (id) => {
        setDownloadPopupShow(true);
        setDownloadAssetID(id);
    }

    const getAssetDetails = async (start,end) => {
        let assetIDCollection = selectedAssetIds.slice(start, end);
        let assetDataCollection = [...respData];
        let APIErrorAssetID = [];
        let apiData = [];
        let assetErrID = [...assetErrorId];
        try{
            apiData = await Promise.allSettled(assetIDCollection.map((assetid) => {
                return axios
                    .get(API.apiURL + "GetAssetCombinedData?assetid=" + assetid + "&subscription-key=" + API.subKey)
                    .then((response) => {   
                        if(response.status === 204){
                            APIErrorAssetID.push(response.config.url.split("assetid=")[1].split("&")[0]);
                        }    
                        if(response.data.eskoResponse.status == "failed"){
                            APIErrorAssetID.push(response.config.url.split("assetid=")[1].split("&")[0]);
                        }                 
                        return response.data.assets[0];
                    })
            }))
        }catch(error){
            console.log(error);
        }
        apiData.map((i, index) => {
            if(i.status === "fulfilled" && i.value !== undefined){
                // if (props.viewLayout === "portal") {
                //     i.value.isSelectIconShow = true;
                //     i.value.downloadIconShow = true;
                // } else {

                if(clickSelectBtn == "unSelectAll"){
                    i.value.isSelectIconShow = true;
                    i.value.isAssetselected = false;
                    i.value.downloadIconShow = true;
                }else if(clickSelectBtn == "selectAll" || clickSelectBtn == ""){
                    i.value.isSelectIconShow = true;
                    i.value.isAssetselected = true;
                    i.value.downloadIconShow = false;
                }
                    
                //}
                
                i.value.filesize = readableBytes(i?.value?.bytes);
                i.value.file_size = i.value.file_size.toFixed(2); 
                assetDataCollection.splice(index + start, 1, i.value);                
            }else if(i.status === "rejected"){  
                //var errorID = selectedAssetIds.splice(start+index,1);
                assetErrID.push(i.reason.config.url.split("assetid=")[1].split("&")[0]);
                setAssetErrorId(assetErrID); 
                var finalID =  selectedAssetIds.filter((value) => {
                    return assetErrID.indexOf(value) < 0;
                });
                setFinalAssetID(finalID);           
                APIErrorAssetID.push(i.reason.config.url.split("assetid=")[1].split("&")[0]);
                assetDataCollection.splice(index + start, 1, i.value);
            }
        })
        let currentData = [...assetDataCollection];
        setRespData(currentData);
        setAssetCount(currentData.length - assetErrID.length);
        let sliceData = currentData.slice(start, end); 
        setCurrentPageAssets([...sliceData]);
        setPageCount(Math.ceil(currentData.length / itemsPerPage));
        if(APIErrorAssetID.length > 0){
            setErrorTitle("Error");
            setErrorContent("The following Asset ID have some issues");
            setErrorValue(APIErrorAssetID.toString());
            setErrorPopupShow(true);
        }
        setAPIloader(false);
    }

    useEffect(() => {
        if (selectedAssetIds?.length > 0) {
            setAPIloader(true);
            getAssetDetails(newOffset,endOffset);
        }
    }, []);

    useEffect(() => {
        assetInfo.assetId = finalAssetID;
    }, [finalAssetID]);

    useEffect(() => {
        if(props?.viewLayout === "preview"){
           // assetInfo.assetId = props?.setAssetIds?.split("=")[1].split(",");
            assetInfo.assetId = finalAssetID;
        }else{
            assetInfo.assetId = props?.portalGridAssetValue;
        }
    }, []);

    return (
        <>
            <AssetGridDetailPopup isShow={popupShowStatus} displayFalse={setPopupShow} detailData={detailPageData}></AssetGridDetailPopup>
            <AssetDownloadPopup isShow={downloadPopupShow} displayDownloadPopup={setDownloadPopupShow} assetId={downloadAssetID}></AssetDownloadPopup>
            <ErrorPopup isShow={errorPopupShow} displayErrorPopup={setErrorPopupShow} errorTitle={errorTitle} errorContent={errorContent} errorValue={errorValue}></ErrorPopup>
            {(APIloader) && (<div className='ajaxLoading'></div>)}
            <div className={style.recordCountContainer}>
                <div className="paginationContainer">
                    <ReactPaginate
                        breakLabel=" "
                        nextLabel=">>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="<<"
                        renderOnZeroPageCount={null}
                    />
                </div>
                <div className={style.btnContainer}>
                    {(selectBtnStatus) &&
                        ((SelectBtnView)
                            ? (<button className={style.selectBtn} onClick={() => { selectAsset("", "", "unSelectAll") }}>Unselect All</button>)
                            : (<button className={style.selectBtn} onClick={() => { selectAsset("", "", "selectAll") }}>Select All</button>)
                        )
                    }
                    <a className={style.downloadAllBtn} href={API.apiURL + "DownloadAsset?assetid=" + assetInfo?.assetId + "&assettype=o&subscription-key=" + API.subKey} disabled={downloadEnable ? true : false} download>{(downloadAllLabel) ? "Download All" : "Download"}</a>
                    <span>{assetCount} items</span>
                </div>
            </div>
            <div className={`${style.gridContainer} portalGrid`}>
                {
                    currentPageAssets.map((i, index) => {      
                        if(i === null || i === undefined){
                            return true;
                        }                  
                        return (
                            <div className={style.assetGridContainer} key={index + newOffset}>
                                <div className={style.imgContainer} onClick={() => showPopupWithDetails(i)}>
                                    <img src={"data:image/png;base64," + i?.image} alt="Asset Grid"></img>
                                </div>
                                <div className={style.assetInfoContainer}>
                                    {i?.name !== null &&
                                        <div className={style.assetTile} title={i?.name}>{i?.name}</div>
                                    }
                                    {(i?.fields.size !== null && i?.fields.unit_of_weight !== null) &&
                                        <div className={style.productSize} title={i?.name}>{i?.fields.size} {i?.fields.unit_of_weight}</div>
                                    }
                                    {i?.fields.product_category !== null &&
                                        <div className={style.productCategory} title={i?.fields.product_category.toString()}>{i?.fields.product_category.toString()}</div>
                                    }
                                    {i?.fields.product_name !== null &&
                                        <div className={style.productName} title={i?.fields.product_name.toString()}>{i?.fields.product_name.toString()}</div>
                                    }
                                    {i?.fields.gtin !== null &&
                                        <div className={style.productGTIN} title={i?.fields.gtin}>{i?.fields.gtin}</div>
                                    }
                                    {i?.fields.country_sold_in !== null &&
                                        <div className={style.productRegion} title={i?.fields.country_sold_in.toString()}>{i?.fields.country_sold_in.toString()}</div>
                                    }
                                </div>
                                <div className={`${style.assetGridIconsContainer} ${i?.isAssetselected && style.gridSelected}`}>
                                    {(i?.isSelectIconShow) && (
                                        <div className={style.selectAsset}>
                                            {(i?.isAssetselected) ? (
                                                <span className={style.icon}>
                                                    <span className="material-icons" onClick={() => selectAsset(i, index + newOffset)}>check_circle</span>
                                                </span>
                                            ) : (
                                                <span className={style.icon}>
                                                    <span className="material-icons" onClick={() => selectAsset(i, index + newOffset)}>check_circle_outline</span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    <div className={style.downloadIcon}>
                                        {/* {(i?.downloadIconShow) && ( */}
                                        {
                                            (i?.name.indexOf(".psd") === -1)?(
                                                <a className={style.downloadImage} href={API.apiURL + "DownloadAsset?assetid=" + i?.id + "&assettype=o&subscription-key=" + API.subKey} download={i?.name}>
                                                    <span className="material-icons" title="You can download this original file">download</span>
                                                </a>
                                            ):(
                                                <span className="material-icons" onClick={()=>showDownloadPopup(i?.id)} title="You can also download this PSD as a PNG or JPEG">download</span>
                                            )
                                        }
                                        {/* )} */}
                                    </div>
                                </div>
                                {( (i?.name.indexOf(".psd") > -1) && (
                                        <span className={style.psdCard}>PSD</span>
                                    )
                                )}
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
}

export default Grid;
