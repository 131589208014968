import React,{useContext,useState} from 'react';
import {Modal,Row,Col,Accordion} from 'react-bootstrap';
import style from './assetGridDetailPopup.module.css';
import AssetDownloadPopup from "../DownloadPopup/assetDownloadPopup";
import { EskoAPI } from '../../Helper/helper';
// import sampleImg from "../../Assets/OceanSpray_logo.png";

const AssetGridDetailPopup = (props) =>{

    const API = useContext(EskoAPI);
    const [downloadPopupShow, setDownloadPopupShow] = useState(false);
   

    const showDownloadPopup = (id) => {
        setDownloadPopupShow(true);
        }

    return(
        <>
            <AssetDownloadPopup isShow={downloadPopupShow} displayDownloadPopup={setDownloadPopupShow} assetId={props.detailData.id}></AssetDownloadPopup>
            <Modal show={props.isShow}>
                <Modal.Body>
                    <div className={style.assetDetailContainer}>
                        <Row className={style.popupContentContainer}>
                            <Col xs={8} className={style.imageDetailContainer}>
                                <div className={style.assetNavBar}>
                                    <div className={style.leftNav}>                                
                                    <span className={`${style.backArrow} material-icons`} onClick={()=>props?.displayFalse(false)}>arrow_back</span>
                                    </div>
                                    <div className={style.rightNav}>
                                        {/* <a className={style.downloadImage} href={API.apiURL+"DownloadAsset?assetid="+props.detailData.id+"&assettype=o&subscription-key="+API.subKey} download={props?.detailData?.name}>
                                            <span className={`${style.navDownload} material-icons`}>download</span>
                                        </a> */}

                                        {(props?.detailData?.name?.indexOf(".psd") === -1)?(
                                            <a className={style.downloadImage} href={API.apiURL + "DownloadAsset?assetid=" + props?.detailData?.id + "&assettype=o&subscription-key=" + API.subKey} download={props?.detailData?.name}>
                                                <span className={`${style.navDownload} material-icons`}>download</span>
                                            </a>
                                        ):(
                                            <span className={`${style.navDownload} material-icons`} onClick={()=>showDownloadPopup(props?.detailData?.id)}>download</span>
                                        )}
                                    </div>
                                    
                                    {/* <button type="button" class="btn-close" aria-label="Close" onClick={()=>props?.displayFalse(false)}></button> */}
                                </div>
                                <div className={style.viewImgContainer}>
                                    <img src={"data:image/png;base64,"+props.detailData.image} alt='sample'></img>
                                </div>
                            </Col>
                            <Col xs={4} className={style.contentDetailContainer}>
                                <h2>Info</h2>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>General</Accordion.Header>
                                        <Accordion.Body>
                                            <div className={style.assetInfo}>
                                                <div className={style.assetCard}>
                                                    <span className={style.assetTitle}>ID Number</span>
                                                    <span className={style.assetValue}>{props?.detailData?.id}</span>
                                                </div> 
                                                <div className={style.assetCard}>
                                                    <span className={style.assetTitle}>File Size</span>
                                                    <span className={style.assetValue}>{props?.detailData?.file_size+` MB`}</span>
                                                </div>
                                                <div className={style.assetCard}>
                                                    <span className={style.assetTitle}>Path</span>
                                                    <span className={style.assetValue}>{props?.detailData?.path}</span>
                                                </div>                                                              
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Ocean Spray</Accordion.Header>
                                        <Accordion.Body>                                            
                                            <div className={style.assetInfo}>
                                                {
                                                    (props?.detailData?.fields != null &&                                                    
                                                        Object.keys(props?.detailData?.fields).map((key,index) => {       
                                                            return (    
                                                               (Object.values(props?.detailData?.fields)[index] != null &&                                            
                                                                <div className={style.assetCard} key={index}>
                                                                    <span className={style.assetTitle}>{Object.keys(props?.detailData?.fields)[index].replaceAll("assetDescription","Description").replaceAll("ian","IAN").replaceAll("product_subcategory","Product Sub Category").replaceAll("corrugate_subtype","Corrugate Sub Type").replaceAll("gtin","GTIN").replaceAll("fruit_flavor","Fruit/Flavor").replaceAll("_"," ")}</span>
                                                                    <span className={style.assetValue} title={Object.values(props?.detailData?.fields)[index]}>{((typeof(Object.values(props?.detailData?.fields)[index]) === "string")?(Object.values(props?.detailData?.fields)[index]):(Object.values(props?.detailData?.fields)[index].toString()))}</span>
                                                                </div> )
                                                            )                                                
                                                        })
                                                    )
                                                } 
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>                            
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>            
            </Modal>
        </>
    );

}

export default AssetGridDetailPopup;