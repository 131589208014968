import React, {useState,useContext} from 'react';
import axios from "axios";
import {EskoAPI} from "../../Helper/helper";
import style from './portalEmailValidation.module.css';
import ErrorPopup from '../ErrorPopup/errorPopup';

function PortalEmailValidation(props){
    const [errorMessageValue, setErrorMessage] = useState("");
    const API = useContext(EskoAPI);
    const [APIloader, setAPIloader] = useState(false);
    const [errorPopupShow, setErrorPopupShow] = useState(false);
    const [errorTitle, setErrorTitle] = useState();
    const [errorContent, setErrorContent] = useState();
    const [errorValue, setErrorValue] = useState("");

    const emailCheck = () =>{
        var emailValue = document.getElementById("portalEmail").value;
        emailValidationCheck(emailValue);
    }

    const checkEmailValidation = (event) => {
        if(event.keyCode === 13){
            emailValidationCheck(event.target.value);
        }
    }

    const CheckEmailwithDB = (email) =>{
        const UUID = props?.setTranctionId?.split("=")[1];
        setAPIloader(true);
        axios
        .get(API.apiURL + "GetAssetByEmail?email="+email+"&uuid="+UUID+"&subscription-key="+API.subKey)
        .then((response) => {            
            if(response.data.eskoResponse.status.toLowerCase() === 'succeeded'){
                props.setGridValue(response.data.assetId);
                props.setTransAssets(response.data.assetId);
                props.setGridView(false); 
                setAPIloader(false);  
            }else if(response.data.eskoResponse.status.toLowerCase() === 'failure'){
                setAPIloader(false);  
                setErrorTitle("Error");                
                setErrorContent(response.data.eskoResponse.description);
                // if(response.data.eskoResponse.statusCode !== "200"){
                //     setErrorValue("Sorry for the inconvenience caused. Please try again after sometime.");
                // }
                setErrorPopupShow(true);
            } 
        })
    }

    const emailValidationCheck = (val) =>{
        if(val === ""){
            setErrorMessage("Please enter the email address");
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)){
            setErrorMessage("Please enter a valid email address");
        }else{
            setErrorMessage("");
            CheckEmailwithDB(val);
        }
    }

    return( 
        <>    
        <ErrorPopup isShow={errorPopupShow} displayErrorPopup={setErrorPopupShow} errorTitle={errorTitle} errorContent={errorContent} errorValue={errorValue}></ErrorPopup>
        <div className={style.portalValidateContainer}>
            {(APIloader) && ( <div className='ajaxLoading'></div>)}
            <div className={style.emailValidation}>
              <label className='form-label'>Please enter your email address:</label>
              <input type='email' name="portalEmail" className='form-control' id="portalEmail" onKeyUp={checkEmailValidation}></input>
              <button className={`${style.portalEmailBtn} btn btn-primary`} onClick={()=>emailCheck()}>Enter</button>
              <span className={style.errorMessage}>{errorMessageValue}</span>
            </div>
        </div>
        </>
    );
}

export default PortalEmailValidation;