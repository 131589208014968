import React,{useContext} from 'react';
import {Modal} from 'react-bootstrap';
import './assetDownloadPopup.css';
import { EskoAPI } from '../../Helper/helper';
import Original from '../../Assets/Original_Image.png';
import PNG from '../../Assets/PNG_Image.png';
import JPG from '../../Assets/JPG_Image.png';

const AssetDownloadPopup = (props) =>{
    const API = useContext(EskoAPI)
    
    return(
        <Modal show={props.isShow} className='downloadPopup'> 
                <Modal.Header>
                    <h1>Download</h1>
                    <button type="button" class="btn-close" aria-label="Close" onClick={()=>props?.displayDownloadPopup(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='downloadPopupContainer'>
                        <a className="downloadAsset" href={API.apiURL + "DownloadAsset?assetid=" + props?.assetId + "&assettype=o&subscription-key=" + API.subKey} download>
                            <img src={Original} alt="Download Original"></img>
                            <span className="convertType">Download Original</span>
                        </a>
                        <a className="downloadAsset" href={API.apiURL + "DownloadAsset?assetid=" + props?.assetId + "&assettype=png&subscription-key=" + API.subKey} download>
                            <img src={PNG} alt="Download PNG"></img>
                            <span className="convertType">Download PSD as PNG</span>
                        </a>
                        <a className="downloadAsset" href={API.apiURL + "DownloadAsset?assetid=" + props?.assetId + "&assettype=jpeg&subscription-key=" + API.subKey} download>
                            <img src={JPG} alt="Download JPG"></img>
                            <span className="convertType">Download PSD as JPG</span>
                        </a>
                    </div>
                </Modal.Body>            
        </Modal>        
    )
}

export default AssetDownloadPopup;