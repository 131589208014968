import React, { useState,useContext } from "react";
//import { useForm } from 'react-hook-form';
import style from './assetSubmitForm.module.css';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import axios from "axios";
import {EskoAPI,AssetInfo} from "../../Helper/helper";
import ErrorPopup from "../ErrorPopup/errorPopup";

const AssetSubmitForm = (props) => {
    const API = useContext(EskoAPI);
    const assetInfo = useContext(AssetInfo);
    //const { register, reset, formState: { errors } } = useForm({ "mode": "onBlur" });
    const [emails, setEmails] = React.useState([]);
    const [emptyEmailField, setEmptyMessage] = useState("");
    //const [invalidEmailField, setInvalidMessage] = useState("");
    const [multiEmailFocusState,setMultiFocusState] = useState(false);
    const [formErrorText,setFormErrorText] = useState("");
    const [errorPopupShow, setErrorPopupShow] = useState(false);    
    const [errorTitle, setErrorTitle] = useState();
    const [errorContent, setErrorContent] = useState();
    const [errorValue, setErrorValue] = useState();
    const [description, setDescription] = useState("");
    const [submitClickBtn, setSubmitClickBtn] = useState(true);

    const validateMultipleEmail = (email) => {    
        if(typeof(email) === "string" && email ==="undefined"){
            email = email.trim();
        }else if(document.querySelector(".email-text-input .data-labels").childNodes.length && (email === "" || email === undefined)){
            if(document.querySelector(".email-text-input input").value.length > 0){
                email = document.querySelector(".email-text-input input").value.trim();
            }else{
                setSubmitClickBtn(false);
                setEmptyMessage("");
                return true;
            }            
        }else if(typeof (email) === 'undefined'){
            email = document.querySelector(".email-text-input input").value.trim()
        }else if(email.length && document.querySelector(".email-text-input input").value.length == 0){
            setEmptyMessage("");
            return true;
        }        
        
        if (email === "" || email.length == 0) {
            setEmptyMessage("Email is required");
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setSubmitClickBtn(true);
            setEmptyMessage("Invalid Email id");
            return false;
        } else {
            setEmptyMessage("");
            return true;
        }
    }

    const checkMultiFocusState = (event) =>{
        if(event.target.name === 'description'){
            if(multiEmailFocusState && emails.length > 0 && document.querySelector(".email-text-input input").value.trim().length === 0){
                setEmptyMessage("");
            }
        }        
    }

    const formReset = () =>{
        //reset();
        document.querySelectorAll("[data-tag]").forEach(function (elem) {
            elem.remove();
        });
        setDescValue("");
        setEmails("");
    }

    const setDescValue = (event) =>{        
        setDescription(event.target.value);
    }

    const formSubmission = (event) => {
        event.preventDefault();
        if(assetInfo?.assetId?.length === 0){   
            if(emails?.length === 0){
                setEmptyMessage("Email is required");
            }else{
                if(document.querySelector(".email-text-input input").value.length == 0){
                    setEmptyMessage("");
                }                
            }      
            setFormErrorText("Please select atleast one asset to share");         
            return false;
        }
        if(emails?.length === 0 || props?.setUserName?.length === 0){
            setEmptyMessage("Email is required");
            setFormErrorText("Please fill the required fields");
            return false;
        }
        const headers = {
            'Content-Type': 'application/json'
        }
        setEmptyMessage("");
        setFormErrorText(""); 
        const data = { 
            "portalentry": {              
              "sent_from": props.setUserName,           
              "description": description,          
              "is_active": true              
            },           
            "portalassetentry": {              
              "asset_id": assetInfo.assetId.toString(),          
              "metadata": "{\"name\":\"product\", \"size\":90}"           
            },          
            "portalentryrecipient": {  
              "email_address": emails.toString()              
            }        
          }   
        axios
        .post(API.apiURL+"PostPortalAsset?subscription-key="+API.subKey,data,{headers: headers})
        .then((response) => {
            if(response.data.status.toLowerCase() === 'success'){
                props.setPreview(false);
                formReset();
            }else if(response.data.status.toLowerCase() === 'failed'){
                setErrorTitle("Error");
                setErrorContent(response.data.description);
                setErrorValue("Sorry for the inconvenience caused. Please try again after sometime.");
                setErrorPopupShow(true);
            }else if(response.status === "400"){
                setErrorTitle("Error");
                setErrorContent(response.title);
                setErrorValue("Sorry for the inconvenience caused. Please try again after sometime.");
                setErrorPopupShow(true);
            }           
        });
       
    }

    const HandleSubmitDisable = (index, removeEmail) => {
        removeEmail(index);        
        setTimeout(()=>{
            if(document.querySelector(".email-text-input .data-labels").childNodes.length > 0){
                setSubmitClickBtn(false);
            }else{
                setSubmitClickBtn(true);
            }            
        }, 100)     
    }

    return (
        <>       
        <ErrorPopup isShow={errorPopupShow} displayErrorPopup={setErrorPopupShow} errorTitle={errorTitle} errorContent={errorContent} errorValue={errorValue}></ErrorPopup>
        <div className={style.assetSubmitForm}>
            <form className={style.assetForm} onSubmit={formSubmission}>
                <div className={style.fromEmailContainer}>
                    <label className={`${style.formLabel} form-label required`}>From:</label>
                    {/* <input className={`${style.formControl} form-control`} type="text" value={props.setUserName} {...register('fromEmail',{value: props.setUserName})} disabled/> */}
                    <input className={`${style.formControl} form-control`} type="text" value={props.setUserName} disabled/>
                    {/* <input className={`${style.formControl} form-control`} onFocus={(event)=>{checkMultiFocusState(event)}} type="text" {...register('fromEmail', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/i })} placeholder="Enter email" />
                    {errors && errors.fromEmail && errors.fromEmail.type === "required" && <span className="err-text">Email is required</span>}
                    {errors && errors.fromEmail && errors.fromEmail.type === "pattern" && <span className="err-text">Invalid Email id</span>} */}
                </div>

                <div className={style.toEmailContainer}>
                    <label className={`${style.formLabel} form-label required`}>To:</label>
                    <ReactMultiEmail
                        placeholder='Enter the email'
                        className={`${style.formControl} form-control email-text-input`}
                        emails={emails}
                        onChange={(_emails) => {
                            setEmails(_emails);
                            (_emails.length == 0)?(setSubmitClickBtn(true)):(setSubmitClickBtn(false));
                           // validateMultipleEmail(_emails);
                        }}
                        onChangeInput={(_emails) => {
                           // setEmails(_emails);
                           validateMultipleEmail(_emails);
                        }}
                        // validateEmail={(_emails) => {
                        //     return validateMultipleEmail(_emails);
                        // }}
                        autoFocus={false}
                        onFocus={()=>{
                            setMultiFocusState(true);
                        }}
                        onBlur={() => {
                            validateMultipleEmail();
                        }}
                        getLabel={(email, index, removeEmail) => {
                            //setSubmitClickBtn(false);
                            return (
                                <div data-tag key={index}>
                                    <div data-tag-item className="multiEmail">{email}</div>
                                    <span data-tag-handle onClick={() => HandleSubmitDisable(index, removeEmail)}>×</span>
                                </div>
                            );
                        }}                        
                    />
                    {emptyEmailField !== "" && <span className="err-text">{emptyEmailField}</span>}
                    {/* {invalidEmailField !== "" && <span className="err-text">{invalidEmailField}</span>} */}
                    {/* <p>{emails.join(', ') || ''}</p> */}
                </div>
                <div className={style.descriptionContainer}>
                    <label className={`${style.formLabel} form-label`}>Description:</label>
                    {/* <textarea aria-label="With textarea" onFocus={(event)=>{checkMultiFocusState(event)}} className={`${style.formControl} form-control`} {...register('description', { required: false })}></textarea> */}
                    <textarea aria-label="With textarea" onFocus={(event)=>{checkMultiFocusState(event)}} className={`${style.formControl} form-control`} value={description} onChange={setDescValue}></textarea>
                </div>
                <div className={style.btnContainer}>
                    <button type="submit" className={`${style.formSubmitBtn} btn btn-primary`} disabled={submitClickBtn}>Submit</button>
                </div>
                <div className={style.errorContainer}>
                    <p className={style.errorText}>{formErrorText}</p>
                </div>
            </form>
        </div>
        </>
    );
}

export default AssetSubmitForm;