import React from 'react';
import {Modal} from 'react-bootstrap';
import './errorPopup.css';

const ErrorPopup = (props) =>{

    return(
        <Modal show={props.isShow} className='errorPopup'> 
                <Modal.Header>
                    {props.errorTitle != null && 
                        <h1>{props.errorTitle}</h1>
                    }
                    <button type="button" class="btn-close" aria-label="Close" onClick={()=>props?.displayErrorPopup(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='errorPopupContainer'>
                        {props.errorContent != null && 
                            <p className='errorContent'>{props.errorContent}</p>
                        }
                        {props.errorValue != null &&                         
                            <p className='errorValue'>{props.errorValue}</p>
                        }
                    </div>
                </Modal.Body>            
        </Modal>        
    )
}

export default ErrorPopup;