import React from "react";
import logo from "../../Assets/OceanSpray_logo.png";
import style from "./header.module.css";
const Header = () =>{
    return(
        <header>
            <div className={style.headerContainer}>
                <div className={style.logoContainer}>
                    <img src={logo} alt="Oceanspray Logo"></img>
                </div>
            </div>
        </header>
    )
}

export default Header;